
<template>
  <div class="the-home">
    <div class="container">
      <b-row >
        <b-col class="text-center" cols="12">
          <h1>แบบประเมิณความเสี่ยง</h1>
          <h2 :class="maincolor">ที่สรรพากรอาจรู้รายได้ของคุณและถูกเรียกเก็บภาษีย้อนหลัง</h2>
          <img class="img" src="../assets/head-line.png" alt="Store Images" style="padding-bottom: 30px;"/>
        </b-col>
      </b-row>
      <b-row>
        <ValidationObserver v-slot="{ handleSubmit, valid }">
  <b-col>
    <TheAssessmentFormCard :questions="questions" />
  </b-col>

  <b-col class="text-center padding" cols="12">
    <b-button
      variant="warning"
      @click="handleSubmit(submit)"

      type="submit"
      :class="{ 'btn-disabled': !valid }"
    >
      <img class="img" src="../assets/icon-view.png" alt="Store Images" height="25px" width="30px" /> ประมวลผล
    </b-button>&nbsp;&nbsp;&nbsp;
    <b-button variant="secondary" @click="refreshComponent">ล้างข้อมูล</b-button>
  </b-col>
</ValidationObserver>


 
      </b-row>
      <b-row>
      </b-row>
    </div>
  </div>
  
</template>
<script>

import TheAssessmentFormCard from "./TheAssessmentFormCard.vue";

// import { required, minLength } from 'vuelidate/lib/validators'
export default {
  name: "TheHome",
  components: {
    TheAssessmentFormCard,
  },
  data() {
    
    return {
      
      questions: [
        
        {          
          title:
            "คุณมีการฝากหรือมีเงินโอนเข้าบัญชีเกิน 3,000 ครั้ง/ปี/ธนาคาร หรือไม่",
          isTrue: null,
          ishighRisk: true, 
          
           
        },
        {
          title:
            "คุณมีการฝากหรือมีเงินโอนเข้าบัญชี 400 ครั้ง/ปี/ธนาคาร และยอดเข้าบัญชีเกิน 2 ล้านบาท/ปี หรือไม่",
          isTrue: null,
          ishighRisk: true,
        },
        {
          title:
            "คุณเคยได้รับดอกเบียเงินฝากออมทรัพย์จากธนาคารบ้างหรือไม่",
          isTrue: null,
          ishighRisk: true,
        },
        {
          title:
            "จำนวนธนาคารที่มีการเปิดบัญชีต่างๆ มีมากกว่า 5 ธนาคาร (เช่น มีบัญชีในธนาคาร SCB 2 บัญชี นับ 1 ธนาคาร )",
          isTrue: null,
          ishighRisk: true,
        },
        {
          title:
            "คุณมีการทำธุรกรรมผ่านระบบ e-Payment (ชำระเงินและทำธุรกรรมบนระบบออนไลน์) บ้างหรือไม่",
          isTrue: null,
          ishighRisk: true,
        },
        {
          title:
            "คุณเคยขายสินค้าโดยเข้าร่วมโครงการของรัฐ เช่น คนละครึ่ง ช้อปดีมีคืน เราชนะ ชิมช้อปใช้ บ้างหรือไม่",
          isTrue: null,
          ishighRisk: true,
        },
        {
          title:
            "คุณเคยขายสินค้าผ่านเว็บ e-commerce เช่น Shopee, Lazada, Grab, Line Man ฯลฯ บ้างหรือไม่",
          isTrue: null,
          ishighRisk: true,
        },
        {
          title:
            "คุณเคยถูกหักภาษี ณ ที่จ่าย จากบริษัทผู้ว่าจ้างบ้างหรือไม่",
          isTrue: null,
          ishighRisk: false,
        },
        {
          title:
            "คุณเคยได้รับใบกำกับภาษี หรือใบเสร็จรับเงิน เช่น ค่าไฟฟ้า ค่าน้ำ ค่าโทรศัพท์ รวมถึงค่าใช้จ่ายอื่นๆ ที่สามารถใช้เป็นหลักฐานได้ว่าเป็นผู้จ่ายเงินจริง บ้างหรือไม่",
          isTrue: null,
          ishighRisk: false,
        },
        {
          title:
            "คุณเคยได้รับค่าน้ำ ค่าไฟ จากบ้านหรือที่พักอาศัยที่ไม่ได้เป็นผู้อยู่จริงหรือไม่",
          isTrue: null,
          ishighRisk: false,
        },
        {
          title:
            "คุณเคยโพสต์หรือไลฟ์สดขายสินค้าผ่านช่องทางออนไลน์ บ้างหรือไม่",
          isTrue: null,
          ishighRisk: false,
        },
        {
          title:
            "คุณเคยใช้การยิง ads ผ่าน facebook, IG, Tiktok, Google หรือไม่",
          isTrue: null,
          ishighRisk: false,
        },
        {
          title:
            "คุณเคยโพสต์โชว์เงินโอนเข้าบัญชี หรือโพสต์รายได้จากการขายสินค้าจำนวนมากผ่าน Facebook, TikTok, IG ฯลฯบ้างหรือไม่",
          isTrue: null,
          ishighRisk: false,
        },
      ],
      maincolor:"maincolor",
      warning:"warning"
    };
  },

  methods: {
    submit() {
  const answeredAllQuestions = this.questions.every(question => question.isTrue !== null);

  if (answeredAllQuestions) {
    this.questions.some(question => {
      if (question.isTrue && question.ishighRisk) {
        this.$router.push("/Theprocess").catch(() => {});
        return true;
      } else {
        this.$router.push("/Theprocesslowrisk").catch(() => {});
        return false;
      }
    });
  } else {
    alert("โปรดตอบคำถามทุกข้อก่อนที่จะส่งแบบประเมิณ");
  }
},

   
refreshComponent() {
  this.questions.forEach(question => {
    question.isTrue = null;
  });
},
    isDisabled() {
      return false
    },
    calculateRisk() {
      this.questions.some(question => {
        console.log(question.title)
        if(question.isTrue && question.ishighRisk) {
          this.$router.push("/Theprocess").catch(()=>{});
          return true     
        }else{
          this.$router.push("/Theprocesslowrisk").catch(()=>{});
          return false
        }
      })
      // for(let i=0; i<this.questions.length ;i++) {
      //   console.log(this.questions[i].title);
      //   if(this.questions[i].isTrue && this.questions[i].ishighRisk) {
      //     alert("high risk")

      //   } else {
      //     alert("low risk")
      //   }
      // }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-disabled {
  background-color: gray;
  color: white;
}

  .maincolor{
  color:#EA6B0D;
  }
  .warning{
    color: #EA6B0D;
  }

  .padding{
    padding-bottom:20px;
    padding-top: 20px;
  }
  .btn-warning {
    color: #ffffff;
    background-color: #EA6B0D;
    border-color: #EA6B0D;
  }

    .btn-warning:hover {
    color: #EA6B0D;
    background-color: #ffffff;
    border-color: #EA6B0D;
  } 
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #EA6B0D;
    border-color: #EA6B0D;
  }
  .btn-warning:focus, .btn-warning.focus {
    color: #ffffff;
    background-color: #EA6B0D;
    border-color: #EA6B0D;
    box-shadow: 0 0 0 0.2rem rgba(239, 166, 20, 0.5);
  }
  .btn-secondary:hover {
    color: #5a6268;
    background-color: #ffffff;
    border-color: #545b62;
}
a:hover {
    color: #0056b3;
    text-decoration: none;
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #EA6B0D !important;
    background-color: #EA6B0D !important;
}

</style>
