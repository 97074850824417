<template>
  <div class="TheProcesslowrisk">
    <TheprocessForm />
  </div>
</template>
<script>
// @ is an alias to /src
import TheprocessForm from "@/components/TheprocessFormlowrisk.vue";

export default {
  name: "ProcesslowriskView",
  components: {
    TheprocessForm,
  },
};
</script>
