<template>
  <div class="TheProcess">
    <TheprocessForm />
  </div>
</template>
<script>
// @ is an alias to /src
import TheprocessForm from "@/components/TheprocessForm.vue";

export default {
  name: "ProcessView",
  components: {
    TheprocessForm,
  },
};
</script>
