
<template>
  <div class="the-home">
    <div class="container">
      <b-row >
        <b-col class="text-center" cols="12">
          <h1>ผลประเมินของคุณ!!</h1>
          <img class="img" src="../assets/head-line.png" alt="Store Images" style="padding-bottom: 30px;"/>
      </b-col>
      </b-row>

    <b-card style="box-shadow: 1px 1px 12px 5px darkgrey; border: 0px solid rgba(0, 0, 0, 0.125);">
      <img class="img" src="../assets/risk.png" alt="Store Images"/>&nbsp;&nbsp;&nbsp;
      <img class="img" src="../assets/l-risk.png" alt="Store Images"  height="50px" width="400px"/>   
     
      <h2>คำอธิบาย</h2>
      <h5>คุณมีความเสี่ยงต่ำที่กรมสรรพากรจะรู้รายได้ เนื่องจากกรมสรรพากรจะใช้วิธีสุ่มตรวจ แต่ถ้าหากแจ็กพอตกรมสรรพากรสุ่มตรวจเจอคุณ 
          โอกาสถูกตรวจสอบและอาจโดนเรียกเก็บภาษีก็จะมีสูงขึ้นแดังนั้น กรณีผู้มีรายได้จากการขายสินค้าและบริการ หากเป็นกลุ่มเสี่ยงที่กรมสรรพากรจะรู้รายได้ 
          และอาจถูกเรียกเก็บภาษีย้อนหลัง ควรตัดสินใจจดบริษัทดำเนินกิจการในนามนิติบุคคล เพื่อให้ผลประกอบการเข้าระบบเป็นไปตามที่กฎหมายกำหนด 
          ซึ่งลักษณะการประกอบกิจการแบบไหนควรจดบริษัทบ้าง สามารถเรียนรู้เพิ่มเติมได้ <a href="https://inflowaccount.co.th/company-registration-2/">ที่นี่มีคำตอบ </a> 
   
      </h5>

      <h6 :class="maincolor">หากต้องการคำแนะนำวางแผนเรื่องภาษีสามารถทักแชทได้ที่ <a :class="maincolor" href="https://lin.ee/911BqsX" target="_blank" rel="noopener noreferrer">@ac567</a>
 หรือ <a :class="maincolor" href="tel:0910059903">โทร.091-005-9903</a>
          เพราะทุกปัญหาเรื่องภาษีใกล้ตัวคุณมากกว่าที่คุณรู้
      </h6>
    </b-card>
      <b-row>
      
        <b-col class="text-center padding" cols="12">
          <router-link to="/">
          <b-button variant="warning">กลับ</b-button>&nbsp;&nbsp;&nbsp;
        </router-link>
        <router-link to="/">
          <b-button variant="warning">ทำแบบทดสอบอีกครั้ง</b-button>
        </router-link>
   
        </b-col>
      </b-row>
      <b-row>
      </b-row>
    </div>
  </div>
</template>
<script>


export default {
  
  data() {
    return {
   
       
      maincolor:"maincolor",
      warning:"warning"
    };
  },
 
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.maincolor{
color:#EA6B0D;
}
.warning{
  color: #EA6B0D;
}

.padding{
  padding-bottom:20px;
  padding-top: 20px;
}
.btn-warning {
    color: #ffffff;
    background-color: #EA6B0D;
    border-color: #EA6B0D;
  }

    .btn-warning:hover {
    color: #EA6B0D;
    background-color: #ffffff;
    border-color: #EA6B0D;
  } 
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #EA6B0D;
    border-color: #EA6B0D;
  }
  .btn-warning:focus, .btn-warning.focus {
    color: #ffffff;
    background-color: #EA6B0D;
    border-color: #EA6B0D;
    box-shadow: 0 0 0 0.2rem rgba(239, 166, 20, 0.5);
  }
  .btn-secondary:hover {
    color: #5a6268;
    background-color: #ffffff;
    border-color: #545b62;
}
a:hover {
    color: #0056b3;
    text-decoration: none;
}

</style>
