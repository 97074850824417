import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import ProcessView from "../views/ProcessView.vue";
import ProcesslowriskView from "../views/ProcesslowriskView";
import TheAssessmentFormCard from "../components/TheAssessmentFormCard.vue";

Vue.use(VueRouter);



const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/TheProcess",
    component: ProcessView,
  },
  {
    path: "/TheProcesslowrisk",
    component: ProcesslowriskView,
  },
  {
    path: "/assessment",
    name: "assessment",
    component: TheAssessmentFormCard,
  },

  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


export default router;
